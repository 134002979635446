// Features.js
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Grid, Card, CardContent, Typography, CardActionArea } from '@mui/material';
import CodeIcon from '@mui/icons-material/Code';
import WebIcon from '@mui/icons-material/Web';
import BuildIcon from '@mui/icons-material/Build';
import SupportIcon from '@mui/icons-material/Support';
import InsightsIcon from '@mui/icons-material/Insights';
import CloudIcon from '@mui/icons-material/Cloud';



const features = [
  {
    icon: <CodeIcon sx={{ fontSize: 40, color: '#8e2de2' }} />,
    title: 'Custom Software Solutions',
    description:
      'Our team specializes in building custom software solutions tailored to meet your unique business needs. We focus on optimizing performance, scalability, and security, ensuring a seamless experience for your end-users.',
  },
  {
    icon: <WebIcon sx={{ fontSize: 40, color: '#4a00e0' }} />,
    title: 'Website Development',
    description:
      'We design and develop responsive, modern websites that engage users, enhance brand visibility, and boost conversions. Our web development services include UI/UX design, frontend and backend integration, and optimization for all devices.',
  },
  {
    icon: <BuildIcon sx={{ fontSize: 40, color: '#2c3e50' }} />,
    title: 'Low-Code Development',
    description:
      'Accelerate your digital transformation with our low-code development services. We help you deploy applications faster and more cost-effectively, leveraging the power of low-code platforms for flexibility and rapid iteration.',
  },
  {
    icon: <SupportIcon sx={{ fontSize: 40, color: '#0f2027' }} />,
    title: 'Ongoing Support & Consultation',
    description:
      'Our commitment doesn’t end after deployment. We provide ongoing support, consultation, and system updates to ensure your software remains functional, secure, and aligned with evolving business needs.',
  },
  {
    icon: <InsightsIcon sx={{ fontSize: 40, color: '#8e2de2' }} />,
    title: 'Data Analysis & Reporting',
    description:
      'Turn your data into actionable insights with our data analysis and reporting services. We provide real-time analytics, custom reports, and visualizations to help you make informed decisions and drive business growth.',
  },
  {
    icon: <CloudIcon sx={{ fontSize: 40, color: '#4a00e0' }} />,
    title: 'Cloud Solutions & Integration',
    description:
      'Optimize your operations with cloud solutions and seamless integration services. We assist with cloud migration, multi-cloud setups, and secure integration of applications, ensuring scalability and robust data management.',
  },
];


const Features = () => {
  const navigate = useNavigate();

  const handleCardClick = () => {
    navigate('/features'); // Route to the Features page
  };

  return (
    <Grid
      id="features"
      container
      spacing={4}
      sx={{
        padding: '50px 20px',
        textAlign: 'center',
        background: 'rgba(15, 32, 39, 0.9)',
      }}
      
    >
      {features.map((feature, index) => (
        <Grid item xs={12} sm={6} md={4} key={index}>
          <Card
            sx={{
              height: '100%',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'space-between',
              transition: 'transform 0.3s ease',
              '&:hover': { transform: 'scale(1.05)' },
              background: 'rgba(255, 255, 255, 0.1)',
              color: '#ffffff',
            }}
          >
            <CardActionArea onClick={handleCardClick} sx={{ flex: 1 }}>
              <CardContent>
                {feature.icon}
                <Typography variant="h6" sx={{ marginTop: 2, fontWeight: 600 }}>
                  {feature.title}
                </Typography>
                <Typography variant="body2" sx={{ marginTop: 1, color: 'rgba(255, 255, 255, 0.8)' }}>
                  {feature.description}
                </Typography>
              </CardContent>
            </CardActionArea>
          </Card>
        </Grid>
      ))}
    </Grid>
  );
};

export default Features;