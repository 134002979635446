// NavBar.js
import React, { useState } from 'react';
import { AppBar, Toolbar, Typography, Button, IconButton, Drawer, List, ListItem, ListItemText, Box } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import { useNavigate, useLocation } from 'react-router-dom';
import { useMediaQuery } from '@mui/material';
import { useEffect } from 'react';

const NavBar = () => {
  const [drawerOpen, setDrawerOpen] = useState(false);
  const isMobile = useMediaQuery('(max-width:600px)'); // Adjust breakpoint as needed
  const navigate = useNavigate(); // useNavigate from react-router-dom for navigation
  const location = useLocation(); // Get current location to check if on landing page

  // Helper function to handle smooth scrolling to sections on the landing page
  const scrollToSection = (sectionId) => {
    const section = document.getElementById(sectionId);
    if (section) {
      section.scrollIntoView({ behavior: 'smooth' });
    }
  };

  // Handle navigation based on section
  const handleNavigation = (path, sectionId = null) => {
    if (location.pathname === path) {
      // If already on the landing page, scroll to the section
      if (sectionId) scrollToSection(sectionId);
    } else {
      // Navigate and scroll to the section after navigation
      navigate(path);
      if (sectionId) {
        setTimeout(() => scrollToSection(sectionId), 300); // Small delay to ensure page loads before scroll
      }
    }
    setDrawerOpen(false); // Closes drawer if on mobile
  };

  useEffect(() => {
    // Close drawer on navigation change
    setDrawerOpen(false);
  }, [location]);

  return (
    <>
      <AppBar position="sticky" sx={{ background: 'rgba(15, 32, 39, 0.8)' }}>
        <Toolbar>
          <Box
            onClick={() => navigate('/')}
            sx={{
              flexGrow: 1,
              cursor: 'pointer',
            }}
          >
            <Typography
              variant="h6"
              sx={{
                fontFamily: "'Roboto Mono', monospace",
                color: '#ffffff',
              }}
            >
              Reignovation
            </Typography>
          </Box>

          {isMobile ? (
            <IconButton
              edge="end"
              color="inherit"
              onClick={() => setDrawerOpen(true)}
              sx={{
                color: '#fff',
                '&:hover': { color: '#8e2de2' },
              }}
            >
              <MenuIcon />
            </IconButton>
          ) : (
            <>
              <Button color="inherit" onClick={() => handleNavigation('/features')}>
                Features
              </Button>
              <Button color="inherit" onClick={() => handleNavigation('/', 'highlights')}>
                Highlights
              </Button>
              <Button color="inherit" onClick={() => handleNavigation('/', 'faq')}>
                FAQ
              </Button>
              <Button color="inherit" onClick={() => handleNavigation('/', 'contact')}>
                Contact Us
              </Button>
            </>
          )}
        </Toolbar>
      </AppBar>

      {/* Drawer for mobile navigation */}
      <Drawer anchor="right" open={drawerOpen} onClose={() => setDrawerOpen(false)}>
        <List sx={{ width: 250 }}>
          <ListItem button onClick={() => handleNavigation('/features')}>
            <ListItemText primary="Features" />
          </ListItem>
          <ListItem button onClick={() => handleNavigation('/', 'highlights')}>
            <ListItemText primary="Highlights" />
          </ListItem>
          <ListItem button onClick={() => handleNavigation('/', 'faq')}>
            <ListItemText primary="FAQ" />
          </ListItem>
          <ListItem button onClick={() => handleNavigation('/', 'contact')}>
            <ListItemText primary="Contact Us" />
          </ListItem>
        </List>
      </Drawer>
    </>
  );
};

export default NavBar;
