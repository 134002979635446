import React from 'react';
import { Box } from '@mui/material';

const BackgroundAnimation = () => {
  return (
    <Box
      sx={{
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        overflow: 'hidden',
        zIndex: -1,
        background: 'radial-gradient(circle at bottom right, #8e2de2, #1a2332 50%, #0f2027 80%)', // Fades from purple to gray
        backgroundSize: '200% 200%', // Make it smaller so it stays in the corner
        animation: 'gradientAnimation 10s ease infinite',
      }}
    />
  );
};

export default BackgroundAnimation;

// CSS for gradient animation
// Include this CSS in your main CSS file, or if using styled-components or a similar solution, place it in the same file.
const styles = `
@keyframes gradientAnimation {
  0% {
    background-position: 100% 100%; // Start from the bottom right corner
  }
  75% {
    background-position: 60% 60%; // Diagonal movement towards the center
  }
  100% {
    background-position: 100% 100%; // Return to the bottom right corner
  }
}
`;
document.head.insertAdjacentHTML('beforeend', `<style>${styles}</style>`);
