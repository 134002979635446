// Footer.js
import React from 'react';
import { Box, Typography, Button, Grid } from '@mui/material';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import { useNavigate, useLocation } from 'react-router-dom';

const Footer = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const scrollToSection = (sectionId) => {
    const section = document.getElementById(sectionId);
    if (section) {
      section.scrollIntoView({ behavior: 'smooth' });
    }
  };

  const handleNavigation = (path, sectionId = null) => {
    if (location.pathname === path) {
      // If already on the correct page, scroll to the section
      if (sectionId) scrollToSection(sectionId);
    } else {
      // Navigate to the specified path and then scroll after loading
      navigate(path);
      if (sectionId) {
        setTimeout(() => scrollToSection(sectionId), 300); // Small delay to allow page load
      }
    }
  };

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  return (
    <Box
      sx={{
        padding: '20px 0',
        textAlign: 'center',
        background: 'rgba(15, 32, 39, 0.9)',
        color: '#ffffff',
      }}
    >
      <Grid container spacing={3} justifyContent="center">
        <Grid item>
          <Button
            color="inherit"
            onClick={() => handleNavigation('/features')}
          >
            Features
          </Button>
        </Grid>
        <Grid item>
          <Button
            color="inherit"
            onClick={() => handleNavigation('/', 'highlights')}
          >
            Highlights
          </Button>
        </Grid>
        <Grid item>
          <Button
            color="inherit"
            onClick={() => handleNavigation('/', 'faq')}
          >
            FAQ
          </Button>
        </Grid>
        <Grid item>
          <Button
            color="inherit"
            onClick={() => handleNavigation('/', 'contact')}
          >
            Contact
          </Button>
        </Grid>
      </Grid>
      <Box sx={{ marginTop: 3 }}>
        <Typography variant="body2" sx={{ color: 'rgba(255, 255, 255, 0.7)' }}>
          &copy; {new Date().getFullYear()} Reignovation. All rights reserved.
        </Typography>
      </Box>
      <Button
        onClick={scrollToTop}
        sx={{
          position: 'fixed',
          bottom: '30px',
          right: '30px',
          background: 'linear-gradient(45deg, #8e2de2, #4a00e0)',
          color: '#ffffff',
          '&:hover': { background: 'linear-gradient(45deg, #4a00e0, #8e2de2)' },
        }}
      >
        <ArrowUpwardIcon />
      </Button>
    </Box>
  );
};

export default Footer;
