// LandingPage.js
import React from 'react';
import BackgroundAnimation from '../components/BackgroundAnimation';
import NavBar from '../components/NavBar';
import Hero from '../components/Hero';
import Features from '../components/Features';
import Highlights from '../components/Highlights';
import FAQ from '../components/FAQ';
import Contact from '../components/Contact';
import Footer from '../components/Footer';
import { Box } from '@mui/material';

const LandingPage = () => {
  return (
    <Box
      sx={{
        position: 'relative',
        overflowX: 'hidden',
        minHeight: '100vh',
        color: '#ffffff',
      }}
    >
      <BackgroundAnimation />
      <NavBar />
      <Hero />
      <Highlights />
      <Features />     
      <FAQ />
      <Contact />
      <Footer />
    </Box>
  );
};

export default LandingPage;
