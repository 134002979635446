// Hero.js
import React, { useEffect, useState } from 'react';
import { Box, Typography, Button } from '@mui/material';

// Adjust these variables to change timings
const SCRAMBLE_SPEED = 70; // milliseconds between each scramble
const SCRAMBLE_LENGTH = 800; // total time scrambling in milliseconds
const WAIT_TIME = 1500; // time the word is shown before next scramble in milliseconds

const phrases = [
  "Create",
  "Collaborate",
  "Innovate",
  "Integrate",
  "Elevate",
  "Design",
  "Develop",
  "Deliver",
];

const Hero = () => {
  const [displayedText, setDisplayedText] = useState("");
  const [currentIndex, setCurrentIndex] = useState(0);
  const [isScrambling, setIsScrambling] = useState(true);

  useEffect(() => {
    const scramble = (text) => {
      let scrambled = text.split('');
      for (let i = scrambled.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));
        [scrambled[i], scrambled[j]] = [scrambled[j], scrambled[i]];
      }
      return scrambled.join('');
    };

    const scrambleInterval = setInterval(() => {
      if (isScrambling) {
        setDisplayedText(scramble(phrases[currentIndex]));
      }
    }, SCRAMBLE_SPEED);

    const revealTimeout = setTimeout(() => {
      setIsScrambling(false);
      setDisplayedText(phrases[currentIndex]);
    }, SCRAMBLE_LENGTH);

    const nextWordTimeout = setTimeout(() => {
      setIsScrambling(true);
      setCurrentIndex((prevIndex) => (prevIndex + 1) % phrases.length);
    }, SCRAMBLE_LENGTH + WAIT_TIME);

    return () => {
      clearInterval(scrambleInterval);
      clearTimeout(revealTimeout);
      clearTimeout(nextWordTimeout);
    };
  }, [currentIndex, isScrambling]);

  return (
    <Box
      id="hero"
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        minHeight: '100vh',
        textAlign: 'center',
        color: '#ffffff',
        background: 'rgba(15, 32, 39, 0.9)',
      }}
    >
      <Typography
        variant="h2"
        sx={{
          fontFamily: "'Roboto Mono', monospace",
          fontWeight: 700,
          animation: 'textReveal 1.5s ease',
          marginBottom: 2,
        }}
      >
        {displayedText}
      </Typography>
      <Typography
        variant="subtitle1"
        sx={{
          fontFamily: "'Roboto', sans-serif",
          maxWidth: '600px',
          marginBottom: 4,
          color: 'rgba(255, 255, 255, 0.8)',
        }}
      >
        Custom solutions to bring your business into the future. With expertise
        in low-code, process optimization, and dedicated support, Reignovation
        is here to elevate your digital experience.
      </Typography>
      <Button
        variant="contained"
        sx={{
          background: 'linear-gradient(45deg, #8e2de2, #4a00e0)',
          color: '#ffffff',
          padding: '10px 20px',
          '&:hover': { background: 'linear-gradient(45deg, #4a00e0, #8e2de2)' },
        }}
        onClick={() =>
          document
            .getElementById('features')
            .scrollIntoView({ behavior: 'smooth' })
        }
      >
        See Our Work
      </Button>
    </Box>
  );
};

export default Hero;

// CSS for text reveal animation
const styles = `
@keyframes textReveal {
  0% {
    transform: translateY(100%);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}
`;
document.head.insertAdjacentHTML('beforeend', `<style>${styles}</style>`);
