// FeaturesPage.js

import React, { useState } from 'react';
import { Box, Typography, Grid, Paper } from '@mui/material';
import { Code, Web, Build, Support, Insights, Cloud } from '@mui/icons-material';
import NavBar from '../components/NavBar';
import Footer from '../components/Footer';
import BackgroundAnimation from '../components/BackgroundAnimation';


const icons = [Code, Web, Build, Support, Insights, Cloud];

const steps = [
  {
    id: 1,
    icon: Code,
    title: 'Discovery & Strategy',
    description: 'We begin by understanding your unique business needs, challenges, and goals. Our team collaborates with you to define a tailored strategy, ensuring that every solution is aligned with your business objectives and vision.',
  },
  {
    id: 2,
    icon: Web,
    title: 'Design & Prototyping',
    description: 'Our designers create user-focused, responsive prototypes that bring your ideas to life. We prioritize seamless UX and visually engaging interfaces that make a strong impression on users and align with your brand identity.',
  },
  {
    id: 3,
    icon: Build,
    title: 'Development & Integration',
    description: 'Once designs are finalized, we dive into development. Using modern, scalable technology, our developers build your solution with a focus on performance, security, and integration with existing systems.',
  },
  {
    id: 4,
    icon: Support,
    title: 'Launch & Ongoing Support',
    description: 'After rigorous testing, your solution is deployed with full operational support. We offer continuous monitoring, updates, and maintenance to ensure your technology evolves with your business needs.',
  },
];

const serviceItems = [
  {
    title: 'Data-Driven Insights & Reporting',
    description: 'Get real-time analytics and reports to make data-driven decisions, with clear insights for growth.',
    icon: Insights,
  },
  {
    title: 'Scalable Cloud Solutions & Integration',
    description: 'Reliable cloud solutions and seamless integrations for secure and scalable data management.',
    icon: Cloud,
  },
  {
    title: 'Continuous Support & Maintenance',
    description: '24/7 support to ensure smooth operation, regular updates, and comprehensive maintenance.',
    icon: Support,
  },
];

const FeaturesPage = () => {
  const [hoveredIndex, setHoveredIndex] = useState(null);

  return (
    <Box sx={{ backgroundColor: '#0f2027', minHeight: '100vh', color: '#FFFFFF' }}>

      {/* Background Glow */}
      <BackgroundAnimation />

      {/* NavBar Component at the Top */}
      <NavBar />

      {/* Main Content */}
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          padding: 4,
        }}
      >
        {/* Steps Section */}
        <Box sx={{ width: '100%', maxWidth: 1200, mt: 8, mb: 4, px: 2 }}>
          <Typography variant="h4" sx={{ color: '#8e2de2', textAlign: 'center', mb: 1 }}>
            Our Approach
          </Typography>
          <Typography variant="h4" sx={{ fontWeight: 'bold', textAlign: 'center', color: '#ffffff', mb: 4 }}>
            Comprehensive Software Solutions Tailored to You
          </Typography>
          <Typography variant="body1" sx={{ color: 'rgba(255, 255, 255, 0.8)', textAlign: 'center', mb: 8 }}>
            Our services are designed to empower your business through innovative technology.
          </Typography>

          <Grid container rowSpacing={4} columnSpacing={4} justifyContent="center" alignItems="stretch" sx={{ px: 2 }}>
            {steps.map((step) => (
              <Grid item xs={12} sm={6} md={6} key={step.id}>
                <Paper
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'space-between',
                    minHeight: '100%',
                    backgroundColor: 'rgba(255, 255, 255, 0.1)',
                    borderRadius: 2,
                    color: '#ffffff',
                    boxShadow: '0px 4px 12px rgba(0,0,0,0.3)',
                    transition: 'transform 0.3s ease',
                    '&:hover': { transform: 'scale(1.05)' },
                  }}
                >
                  <Box sx={{ textAlign: 'center', py: 2 }}>
                    <step.icon sx={{ fontSize: 60, color: '#8e2de2' }} />
                  </Box>
                  <Box sx={{ textAlign: 'center', p: 2 }}>
                    <Typography variant="subtitle2" sx={{ color: '#ffffff', mb: 1 }}>
                      Step {step.id.toString().padStart(2, '0')}
                    </Typography>
                    <Typography variant="h6" sx={{ fontWeight: 'bold', color: '#ffffff', mb: 1 }}>
                      {step.title}
                    </Typography>
                    <Typography variant="body2" sx={{ color: 'rgba(255, 255, 255, 0.8)' }}>
                      {step.description}
                    </Typography>
                  </Box>
                </Paper>
              </Grid>
            ))}
          </Grid>
        </Box>

        {/* Spacer */}
        <Box sx={{ height: '100px' }} />

        
        {/* Carousel Section */}
        <Typography variant="h4" sx={{ color: '#8e2de2', mb: 1 }}>
        Our Expertise
        </Typography>
        <Typography variant="h4" sx={{ fontWeight: 'bold', textAlign: 'center', color: '#ffffff', mb: 4 }}>
        Advanced, Custom Solutions for Your Business Needs
        </Typography>

        <Box
        sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            position: 'relative',
            width: '100%',
            maxWidth: 800,
            gap: -4,
        }}
        >
        {icons.map((IconComponent, index) => {
            const centerIndex = Math.floor(icons.length / 2);
            const distanceFromCenter = Math.abs((hoveredIndex ?? centerIndex) - index);
            const scale = hoveredIndex === index ? 1.6 : 1 - distanceFromCenter * 0.2;

            return (
            <Box
                key={index}
                onMouseEnter={() => setHoveredIndex(index)}
                onMouseLeave={() => setHoveredIndex(null)}
                sx={{
                position: 'relative',
                zIndex: hoveredIndex === index ? 10 : 10 - distanceFromCenter,
                transform: `scale(${scale})`,
                transition: 'transform 0.3s ease, color 0.3s ease',
                padding: 2,
                }}
            >
                <IconComponent
                sx={{
                    fontSize: 100,
                    color: hoveredIndex === index ? '#8e2de2' : '#4a00e0', // Changes color on hover
                    transition: 'color 0.3s ease',
                }}
                />
            </Box>
            );
        })}
        </Box>

        {/* Additional Content Below Carousel */}
        <Box
        sx={{
            mt: 8,
            textAlign: 'center',
            width: '100%',
            maxWidth: 1000,
            px: 2,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
        }}
        >
        <Typography
            variant="h4"
            sx={{
            fontWeight: 'bold',
            color: '#ffffff',
            mb: 4,
            animation: 'fadeIn 1s ease-in-out',
            }}
        >
            Why Choose Our Solutions?
        </Typography>

        <Box
            sx={{
            display: 'grid',
            gridTemplateColumns: { xs: '1fr', sm: '1fr 1fr', md: '1fr 1fr 1fr' },
            gap: 4,
            width: '100%',
            mt: 4,
            }}
        >
            <Box
            sx={{
                p: 4,
                backgroundColor: 'rgba(15, 32, 39, 0.8)',
                borderRadius: 2,
                boxShadow: '0px 4px 12px rgba(0,0,0,0.4)',
                color: '#ffffff',
                textAlign: 'center',
                transition: 'transform 0.3s ease',
                '&:hover': {
                transform: 'translateY(-10px)',
                backgroundColor: 'rgba(142, 45, 226, 0.9)',
                },
            }}
            >
            <Typography variant="h6" sx={{ fontWeight: 'bold', mb: 2 }}>
                Tailored Solutions
            </Typography>
            <Typography variant="body2" sx={{ color: 'rgba(255, 255, 255, 0.8)' }}>
                Every project is crafted to fit your unique business needs, ensuring maximum impact and effectiveness.
            </Typography>
            </Box>

            <Box
            sx={{
                p: 4,
                backgroundColor: 'rgba(15, 32, 39, 0.8)',
                borderRadius: 2,
                boxShadow: '0px 4px 12px rgba(0,0,0,0.4)',
                color: '#ffffff',
                textAlign: 'center',
                transition: 'transform 0.3s ease',
                '&:hover': {
                transform: 'translateY(-10px)',
                backgroundColor: 'rgba(142, 45, 226, 0.9)',
                },
            }}
            >
            <Typography variant="h6" sx={{ fontWeight: 'bold', mb: 2 }}>
                Cutting-Edge Technology
            </Typography>
            <Typography variant="body2" sx={{ color: 'rgba(255, 255, 255, 0.8)' }}>
                We leverage the latest in technology to provide solutions that are not only modern but also scalable and secure.
            </Typography>
            </Box>

            <Box
            sx={{
                p: 4,
                backgroundColor: 'rgba(15, 32, 39, 0.8)',
                borderRadius: 2,
                boxShadow: '0px 4px 12px rgba(0,0,0,0.4)',
                color: '#ffffff',
                textAlign: 'center',
                transition: 'transform 0.3s ease',
                '&:hover': {
                transform: 'translateY(-10px)',
                backgroundColor: 'rgba(142, 45, 226, 0.9)',
                },
            }}
            >
            <Typography variant="h6" sx={{ fontWeight: 'bold', mb: 2 }}>
                Long-Term Partnership
            </Typography>
            <Typography variant="body2" sx={{ color: 'rgba(255, 255, 255, 0.8)' }}>
                We’re here for the long run, providing support and growth strategies that evolve with your business.
            </Typography>
            </Box>
        </Box>
        </Box>

        {/* Service Offerings Section */}
        <Box
          sx={{
            width: '100%',
            maxWidth: 1200,
            display: 'grid',
            gridTemplateColumns: { xs: '1fr', sm: '1fr 1fr', md: '1fr 1fr 1fr' },
            gap: 4,
            mt: 12,
            px: 2,
          }}
        >
          {serviceItems.map((item, index) => (
            <Paper
              key={index}
              sx={{
                p: 3,
                backgroundColor: 'rgba(15, 32, 39, 0.8)',
                borderRadius: 2,
                color: '#ffffff',
                textAlign: 'center',
                boxShadow: '0px 4px 20px rgba(0,0,0,0.4)',
                transition: 'transform 0.3s ease, background-color 0.3s ease',
                '&:hover': {
                  transform: 'scale(1.05)',
                  backgroundColor: 'rgba(142, 45, 226, 0.9)',
                },
              }}
            >
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  fontSize: 50,
                  mb: 2,
                  color: '#ffffff',
                  transition: 'color 0.3s ease',
                  '&:hover': {
                    color: '#4a00e0',
                  },
                }}
              >
                <item.icon sx={{ fontSize: 50 }} />
              </Box>
              <Typography variant="h6" sx={{ fontWeight: 'bold', mb: 1 }}>
                {item.title}
              </Typography>
              <Typography variant="body2" sx={{ color: 'rgba(255, 255, 255, 0.8)' }}>
                {item.description}
              </Typography>
            </Paper>
          ))}
        </Box>

        {/* Spacer */}
        <Box sx={{ height: '100px' }} />


      </Box>
        
        {/* Footer Component at the Bottom */}
        <Footer />
    </Box>
  );
};

export default FeaturesPage;
