// Highlights.js
import React from 'react';
import { Box, Typography, Paper } from '@mui/material';
import RocketLaunchIcon from '@mui/icons-material/RocketLaunch';
import SecurityIcon from '@mui/icons-material/Security';
import SpeedIcon from '@mui/icons-material/Speed';
import DoneIcon from '@mui/icons-material/Done';
import CloudIcon from '@mui/icons-material/Cloud';
import SupportIcon from '@mui/icons-material/Support';

const highlights = [
  { icon: <RocketLaunchIcon sx={{ fontSize: 36, color: '#8e2de2' }} />, title: 'Rapid Deployment' },
  { icon: <SecurityIcon sx={{ fontSize: 36, color: '#4a00e0' }} />, title: 'Robust Security' },
  { icon: <SpeedIcon sx={{ fontSize: 36, color: '#2c3e50' }} />, title: 'Performance Optimization' },
  { icon: <DoneIcon sx={{ fontSize: 36, color: '#0f2027' }} />, title: 'End-to-End Solutions' },
  { icon: <CloudIcon sx={{ fontSize: 36, color: '#8e2de2' }} />, title: 'Cloud Solutions' },
  { icon: <SupportIcon sx={{ fontSize: 36, color: '#4a00e0' }} />, title: '24/7 Support' },
];

// Duplicate the highlights to ensure continuous scrolling
const scrollingHighlights = [...highlights, ...highlights];

const Highlights = () => {
  return (
    <Box
      id="highlights"
      sx={{
        position: 'relative',
        overflow: 'hidden',
        padding: '20px 0', // Added padding to center the cards vertically
        // Animated moving gradient background
        background: 'linear-gradient(270deg, #8e2de2, #4a00e0, #0f2027, #8e2de2)',
        backgroundSize: '400% 400%',
        animation: 'gradientAnimation 30s ease infinite',
      }}
    >
      <Box
        className="scrollContainer"
        sx={{
          display: 'flex',
          alignItems: 'center',
          animation: 'scrollRightToLeft 10s linear infinite',//scroll speed
        }}
      >
        {scrollingHighlights.map((highlight, index) => (
          <Paper
            key={index}
            elevation={3}
            sx={{
              minWidth: '150px',
              height: '100px',
              flexShrink: 0,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
              margin: '0 20px',
              borderRadius: '10px',
              padding: '10px',
              backgroundColor: '#263238',
              transition: 'transform .3s ease',
              '&:hover': {
                transform: 'scale(1.1)',
              },
            }}
          >
            {highlight.icon}
            <Typography
              variant="h6"
              sx={{
                fontSize: '0.85rem',
                marginTop: 0,
                fontWeight: 500,
                color: '#ffffff',
                textAlign: 'center',
              }}
            >
              {highlight.title}
            </Typography>
          </Paper>
        ))}
      </Box>
    </Box>
  );
};

export default Highlights;

// CSS for endless scrolling animation and moving gradient background
const styles = `
@keyframes scrollRightToLeft {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-50%);
  }
}

@keyframes gradientAnimation {
  0% { background-position: 0% 50%; }
  50% { background-position: 100% 50%; }
  100% { background-position: 0% 50%; }
}
`;
document.head.insertAdjacentHTML('beforeend', `<style>${styles}</style>`);
