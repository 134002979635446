import React, { useState } from 'react';
import { Box, Typography, TextField, Button, Grid } from '@mui/material';
import BackgroundAnimation from './BackgroundAnimation'; // Ensure the import path is correct

const Contact = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    message: '',
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleSubmit = () => {
    const { name, email, message } = formData;
    window.location.href = `mailto:support@reignovation.com?subject=Contact from ${name}&body=${message} (Reply to: ${email})`;
  };

  return (
    <Box
      sx={{
        width: '100%',
        py: 8,
        display: 'flex',
        justifyContent: 'center',
        position: 'relative',
        overflow: 'hidden',
      }}
    >
      {/* Background Animation */}
      <BackgroundAnimation />

      {/* Inner Box with constrained width for content */}
      <Box
        id="contact"
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          background: 'rgba(20, 40, 50, 0.95)',
          color: '#ffffff',
          borderRadius: '12px',
          maxWidth: '800px',
          padding: '40px',
          boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.5)',
          width: '100%',
          mx: 2,
          position: 'relative',
          zIndex: 1,
        }}
      >
        <Grid container spacing={4}>
          <Grid item xs={12} md={6}>
            <Typography variant="h4" gutterBottom sx={{ fontWeight: 'bold' }}>
              Let’s Connect!
            </Typography>
            <Typography variant="body1" sx={{ color: 'rgba(255, 255, 255, 0.85)', mb: 3 }}>
              Have questions or want to learn more? We’re here to help! Send us a message, and we’ll get back to you shortly.
            </Typography>
          </Grid>

          {/* Right side - Contact Form */}
          <Grid item xs={12} md={6}>
            <Box
              component="form"
              sx={{
                display: 'flex',
                flexDirection: 'column',
                width: '100%',
                backgroundColor: 'rgba(20, 40, 50, 0.9)',
                borderRadius: '8px',
                padding: '10px',
                boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.3)',
              }}
            >
              <TextField
                label="Name"
                name="name"
                variant="filled"
                fullWidth
                sx={{
                  mb: 2,
                  background: 'rgba(255, 255, 255, 0.1)',
                  borderRadius: '5px',
                  '&:hover': { background: 'rgba(255, 255, 255, 0.2)' },
                }}
                InputProps={{
                  style: { color: '#ffffff' },
                }}
                InputLabelProps={{ style: { color: 'rgba(255, 255, 255, 0.7)' } }}
                value={formData.name}
                onChange={handleChange}
              />
              <TextField
                label="Email"
                name="email"
                variant="filled"
                fullWidth
                sx={{
                  mb: 2,
                  background: 'rgba(255, 255, 255, 0.1)',
                  borderRadius: '5px',
                  '&:hover': { background: 'rgba(255, 255, 255, 0.2)' },
                }}
                InputProps={{
                  style: { color: '#ffffff' },
                }}
                InputLabelProps={{ style: { color: 'rgba(255, 255, 255, 0.7)' } }}
                value={formData.email}
                onChange={handleChange}
              />
              <TextField
                label="Message"
                name="message"
                variant="filled"
                fullWidth
                multiline
                rows={4}
                sx={{
                  mb: 3,
                  background: 'rgba(255, 255, 255, 0.1)',
                  borderRadius: '5px',
                  '&:hover': { background: 'rgba(255, 255, 255, 0.2)' },
                }}
                InputProps={{
                  style: { color: '#ffffff' },
                }}
                InputLabelProps={{ style: { color: 'rgba(255, 255, 255, 0.7)' } }}
                value={formData.message}
                onChange={handleChange}
              />
              <Button
                variant="contained"
                sx={{
                  background: 'linear-gradient(45deg, #8e2de2, #4a00e0)',
                  color: '#ffffff',
                  fontWeight: 'bold',
                  fontSize: '1rem',
                  borderRadius: '8px',
                  '&:hover': { background: 'linear-gradient(45deg, #4a00e0, #8e2de2)' },
                  alignSelf: 'center',
                  width: '100%',
                  py: 1.5,
                }}
                onClick={handleSubmit}
              >
                Send Message
              </Button>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default Contact;
