// FAQ.js
import React from 'react';
import { Accordion, AccordionSummary, AccordionDetails, Typography, Box } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import BackgroundAnimation from './BackgroundAnimation';

const faqData = [
  {
    question: 'What industries do you specialize in?',
    answer: 'We provide solutions for various industries, including finance, healthcare, retail, and more, with a focus on customized digital transformation.',
  },
  {
    question: 'How does low-code development benefit my business?',
    answer: 'Low-code development accelerates the time to market, reduces costs, and offers flexibility, allowing your business to adapt quickly to new demands.',
  },
  {
    question: 'What kind of support do you offer after project completion?',
    answer: 'We provide ongoing support and consultation to ensure that your software remains effective, updated, and aligned with your business goals.',
  },
  {
    question: 'How secure are the solutions you provide?',
    answer: 'Security is a top priority. We implement industry-leading security measures to protect your data and digital assets at every development stage.',
  },
];

const FAQ = () => {
  return (
    <Box
      id="faq"
      sx={{
        padding: '50px 20px',
        textAlign: 'center',
        background: 'rgba(15, 32, 39, 0.9)',
        color: '#ffffff',
      }}
    >
          {/* Background Animation */}
      <BackgroundAnimation />
      <Typography variant="h4" gutterBottom>
        Frequently Asked Questions
      </Typography>
      {faqData.map((item, index) => (
        <Accordion
          key={index}
          sx={{
            background: 'rgba(255, 255, 255, 0.1)',
            color: '#ffffff',
            '&:hover': { background: 'rgba(255, 255, 255, 0.15)' },
          }}
        >
          <AccordionSummary expandIcon={<ExpandMoreIcon sx={{ color: '#8e2de2' }} />}>
            <Typography variant="h6">{item.question}</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography variant="body2" sx={{ color: 'rgba(255, 255, 255, 0.8)' }}>
              {item.answer}
            </Typography>
          </AccordionDetails>
        </Accordion>
      ))}
    </Box>
  );
};

export default FAQ;
